<template>
    <div id="courseIntroductionDetails">
        <div class="box">
            <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">首页</span>>
                <span style="color: #999999;cursor: pointer;" @click="courseClick">精品案例</span>>
                <span style="color: #999999;cursor: pointer;" @click="detailsClick">精品案例详情</span>>
                <span>施工图纸</span>
            </div>

            <div style="width:100%;height: 1040px;display: flex;justify-content: space-between;">
                <div style="width: 100%; float: left; display: flex;flex-direction: column;align-items: center;">
                    <div style="width:100%;height:40px;background:#fff;color:#76C8F6;text-align:center;line-height:40px;font-size: 18px;font-weight:600">
                        {{caseName}}工程施工图纸
                    </div>
                    <!-- <div style="width: 100%;height: 1000px;">
                    <vue-office-excel
                    v-if="fileShow == 1"
                    :src="fileSrcUrl"
                    />
                    <vue-office-docx 
                    v-if="fileShow == 2"
                        :src="fileSrcUrl"
                    />
                    <vue-office-pdf 
                    v-if="fileShow == 3"
                        :src="fileSrcUrl"
                    />
                    
                    <iframe v-if="fileShow == 4" :src="fileSrcUrl"  frameborder="0" style="width: 100%; height: 100%"></iframe>
                    <div style="height:100%;overflow-y:auto" v-if="fileShow == 5">
                        <img style="width:100%" v-for="(item,index) in imgList" :key="index" :src="item" alt="" srcset="">
                    </div>
                </div> -->
                <div style="width: 100%;">
                        <div id="renderContainer"></div>
                    </div>
                </div>
                <div class="rightBox">
                    <div style="width: 100%;height: 50px;line-height: 50px;text-align: center;border-bottom: 1px solid #F7F8FA;font-weight: bold;color: #333333;">
                        目录
                    </div>
                    <el-collapse  style="margin: 10px;">
                        <el-collapse-item v-for="(item,index) in modelTreeData" :key="index"  :name="index">
                            <template #title>
                                <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                    <span style="font-size: 16px;font-weight: 500;color: #333333;padding-left: 20px;"></span><span style="padding-left: 20px;">{{ item.cdName }}</span>
                                </div>
                            </template>
                            <div style="margin-left: 50px;margin-top: 10px;cursor: pointer;" v-for="(item2,index) in item.child" :key="index" @click="pdfClick(item2)">
                                <p>
                                    <img style="vertical-align: middle;cursor: pointer;margin-right:10px" :src="require('@/assets/img/courseTechingimg/cad (1).png')" alt="">
                                    <span>{{item2.cdName}}</span>
                                </p>
                            </div>              
                        </el-collapse-item>

        <!-- <el-collapse-item name="1">
                <template #title>
                    <span style="font-size: 16px;font-weight: 500;color: #333333;padding-left: 20px;">一、主体结构施工图纸</span>
                </template>
                <div style="margin-left: 80px;margin-top: 10px;">
                    <p><img style="vertical-align: middle;cursor: pointer;margin-right:10px" :src="require('@/assets/img/courseTechingimg/cad (1).png')" alt=""><span>1层主体结构施工图纸</span></p>
                </div>
                <div style="margin-left: 80px;margin-top: 10px;">
                    <p><img style="vertical-align: middle;cursor: pointer;margin-right:10px" :src="require('@/assets/img/courseTechingimg/cad (1).png')" alt=""><span>项目建议书审批意见及前期工作通知书</span></p>
                </div>                   
              </el-collapse-item>
              <el-collapse-item  name="2">
                <template #title>
                    <span style="font-size: 16px;font-weight: 500;color: #333333;padding-left: 20px;">二、临建施工图纸</span>
                </template>
              </el-collapse-item>
              <el-collapse-item  name="3">
                <template #title>
                    <span style="font-size: 16px;font-weight: 500;color: #333333;padding-left: 20px;">三、水电网施工图纸</span>
                </template>
               
              </el-collapse-item>
              -->
            </el-collapse>
                </div>
            </div>
        
        </div>
        
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,} from "vue";
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl,baseUrl,reception} from "@/js/util"
import {f_get_csConstructionDrawings,Show_File}  from '../../../js/public'

//引入VueOfficeDocx组件
import VueOfficeDocx from '@vue-office/docx'
//引入相关样式
import '@vue-office/docx/lib/index.css'
//引入VueOfficeExcel组件
import VueOfficeExcel from '@vue-office/excel'
//引入相关样式
import '@vue-office/excel/lib/index.css'
import VueOfficePdf from '@vue-office/pdf'
import { pkpmView, InnerExtensions, ViewEventType, Utilities,Point3,Drawing3D,InstanceEventType,Vector3 } from 'pkpm.ViewEngine'
import bimModel from '../../../components/bimCodeModel.vue'
import { ElMessage, ElMessageBox } from "element-plus";
export default {
    setup () {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const state = reactive({
            documentChildren:false,
            Url:'',
            fileSrcUrl:'',
            TUzhiArr:[],
            modelTreeData:[],
            title:'',
            id:route.query.id,
            caseName:route.query.caseName,
            baseUrl:baseUrl,
            fileShow:'',
            imgList:[],
            viewOptions :{
            wokringDirectory: reception+'/#/home/drawings', // 前端路径，
            containerId: 'renderContainer', // 加载引擎的DOM元素
            token: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjREODJDRUVBRDhEMDAyODk2NjJDNjExMzAwRkJGN0M4NzU0Q0I1OEFSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IlRZTE82dGpRQW9sbUxHRVRBUHYzeUhWTXRZbyJ9.eyJuYmYiOjE2NzgwODM4MDIsImV4cCI6MTY3ODA4NTAwMiwiaXNzIjoiaHR0cHM6Ly91Yy5hcGkucGtwbS5jbiIsImF1ZCI6WyJhcGkxIiwiaHR0cHM6Ly91Yy5hcGkucGtwbS5jbi9yZXNvdXJjZXMiXSwiY2xpZW50X2lkIjoiVW5pZmllZF9BY2Nlc3NfU2VydmljZSIsInN1YiI6IjE0MzA2OSIsImF1dGhfdGltZSI6MTY3ODA4MzgwMiwiaWRwIjoibG9jYWwiLCJuYW1laWQiOiIxNDMwNjkiLCJ1bmlxdWVfbmFtZSI6IjE0aHlnTVd3WkxFIiwiYXBwSWQiOiJVbmlmaWVkX0FjY2Vzc19TZXJ2aWNlIiwicmVmcmVzaFRva2VuIjoiNmY0ZDcwNjQtNmJiNC00N2MzLTkxODgtYjM5ODE0YTY2MzEzIiwibGFzdExvZ2luVGltZSI6IjAzLzA2LzIwMjMgMTQ6MjM6MjIgKzA4OjAwIiwibG9naW5UeXBlIjoiUGVyc29uYWwiLCJqdGkiOiJDNjIyRTY2NkMwOUVBQUIzMEVDRTc2MUVGOTUyQjc3MCIsImlhdCI6MTY3ODA4MzgwMiwic2NvcGUiOlsiYXBpMSIsIm9wZW5pZCIsInByb2ZpbGUiLCJvZmZsaW5lX2FjY2VzcyJdLCJhbXIiOlsicHdkIl19.AHwnItuwtkXebaqp5cMXeW51EcerW2o2aGLmKBvLova6YJVmKzJIZ4MN45pfwx3S-HSjnWJbCd79Go4ZagVum8YqX9ntc7BTJ2XoEvzpeipdMf783Za_TsxLjy1_llSQgpS0eSbe6Gv2trU9xLhdvR60wOqS8slC28NHDUkXg9xL3A_oCsP6mv39TM2wfhrzar_mjFcc2JrnK2N9p-msjEe39MkkUJyaTGwobqMaIqMfzLwYLnjwHbPLG24r85NV9U6LppTP_5i8CPZQa3nm6NpeksF0yH_W7nqJzJ_WTSuW3LmBivonK9F1ysAHh3eK0jwHIi5D9pTHSDuTP2K0WA', // 访问平台、获取模型token
            dmsEndpoint: 'https://cloud.dt.pkpm.cn',
            env: "prod",
            extensions: [
            InnerExtensions.DefaultTool,  // 默认工具，包括旋转、移动和放大缩小
            InnerExtensions.Home,
            InnerExtensions.FitWindow,
            InnerExtensions.FullScreen,
            InnerExtensions.BackgroundColor,
            InnerExtensions.BasicInfo, //基础信息
            InnerExtensions.Settings, //设置
            InnerExtensions.Isolation, //隔离
            InnerExtensions.Hiding, //隐藏
            InnerExtensions.Attributes, //属性
            InnerExtensions.DWGLayer, //DWG图层
            InnerExtensions.InstanceTree,// 构件树
            InnerExtensions.Pushpin,
            InnerExtensions.TitleBlock, //二维ocr支持
            InnerExtensions.pdf
            ], // 需要加载的插件
            
            excludeExtensions: [
           
            ] // 引擎默认加载但是不需要的些插件
            },
            pushpinMgr:'',
            pastId:'',
            p1:'',
            p2:'',
            p3:'',
            p4:'',
            pushpins:[],
            modelShow:false,
        })
    const methods = {
        // 获取施工图纸
        f_get_csConstructionDrawings(){
            let params = {
                caseSharingId:state.id,
                cdType:1
            }
            f_get_csConstructionDrawings(params).then(res=>{
                if (res.data.code == 1) {
                    console.log(res);
                    state.modelTreeData = res.data.data;
                    if (state.modelTreeData.length >0) {
                        methods.getViewModel()
                    }
                    // setTimeout(() => {
                        
                    // }, 1000);
                    // state.TUzhiArr = res.data.data;
                    // state.fileSrcUrl =state.baseUrl+'/'+ state.TUzhiArr[0].child[0].fileData.filePath;
                    // state.indexedDB = state.TUzhiArr[0].child[0].fileData.Id;
                    // const index = state.TUzhiArr[0].child[0].fileData.fileName.lastIndexOf('.')
                    // const resvles = state.TUzhiArr[0].child[0].fileData.fileName.substring(index+1,state.TUzhiArr[0].child[0].fileData.fileName.length);
                    // if (resvles == 'xlsx') {
                    //     state.fileShow = 1;
                    // }else if(resvles == 'docx'){
                    //     state.fileShow = 2;
                    // }else if(resvles == 'pdf'){
                    //     state.fileShow = 4;
                    // }else if(resvles == 'png' || resvles == 'jpg'){
                    //     state.fileShow = 4;
                    // }else if(resvles == 'ppt' || resvles == 'pptx'){
                    //     state.fileShow = 5;
                    //     methods.Show_File(2,state.TUzhiArr[0].child[0].fileData.filePath)
                    // }else if(resvles == 'doc'){
                    //     methods.Show_File(1,state.TUzhiArr[0].child[0].fileData.filePath)
                    //     state.fileShow = 4;
                        
                    // }
                }
            })
        },
        getViewModel: () =>{
            
            state.viewApp = new pkpmView(state.viewOptions) // 创建实例
            console.log(state.viewApp.loadModels,'一开始');
            state.viewApp.iView.subscribe({ next: (e) => {
                switch(e.type) {
                case 
                ViewEventType.EXTENSION_LOADED:
                    if (e.data.type === InnerExtensions.Pushpin) {
                        state.pushpinMgr = e.data.app;
                        state.pushpinMgr.subscribe({ next: (e) => {
                            switch (e.type) {
                            case ViewEventType.PUSHPIN_CREATED:
                            const pin = e.data.pin;
                                state.pushpins.push(pin);    
                            break;
                            case ViewEventType.PUSHPIN_REMOVED:

                            break;
                            case ViewEventType.ON_PUSHPIN_CLICKED:
                                
                            break;
                            }
                            }})
                        }
                        break
                         case ViewEventType.SELECTION_ADDED:
                            
                        if (!e.data.objectID) return;
                        console.log(e);
                        const aabb = e.data.aabb;
                        const min = aabb.min;
                        const max = aabb.max;
                        const minx = Math.min(min[0], max[0])
                        const miny = Math.min(min[1], max[1])
                        const minz = Math.min(min[2], max[2])
                        const maxx = Math.max(min[0], max[0])
                        const maxy = Math.max(min[1], max[1])
                        const maxz = Math.max(min[2], max[2])

                        state.p1 = Vector3.create(minx, maxy, (minz + maxz) / 2);
                        const p2 = Vector3.create(minx, maxy + 10, (minz + maxz) / 2);
                        const p3 = Vector3.create(maxx, maxy + 15, (minz + maxz) / 2);
                        state.p4 = Vector3.create(maxx, maxy, (minz + maxz) / 2);
                        const p5 = Vector3.create((minx + maxx) / 2, maxy + 5, (minz + maxz) / 2);
                        const imgurl = '';
                        state.viewApp.iView.scene.removePostSceneGroup('test');
                        state.pushpinMgr.addPushpinByText(1, p2, '构件ID'+e.data.dbID, { fontColor: 'black', bkColor: 'white', fontSize: 18, width: 2.4, height: 1.7}, false);
                        // state.pushpinMgr.addPushpinImage(1, p5, imgurl, { fontColor: 'blue', bkColor: 'white', fontSize: 24, width: 7.4, height: 2.7}, false)

                        break;
                        case ViewEventType.SELECTION_REMOVED:
                        console.log(state.pushpins);
                        if (state.pushpins.length > 0) {
                            state.pushpins.forEach((pushpins, pp) => {
                                console.log(pushpins);
                            state.pushpinMgr.removePushpin(pushpins.id);
                        })
                        }
                        break;
                        }   
                   

            }})
                
            state.viewApp.start() // 启动
            state.pastId = state.modelTreeData[0].child[0].file;
            state.modelIndex = state.modelTreeData[0].child[0].file;
            const index = state.modelTreeData[0].child[0].fileData.fileName.lastIndexOf('.');
                const format = '.'+state.modelTreeData[0].child[0].fileData.fileName.substring(index+1,state.modelTreeData[0].child[0].fileData.fileName.length);
            console.log( state.pastId);
            state.viewApp.loadModels([{documentId: state.pastId, type: format, version: 1, name: "A_FZJD_平面02_模型.0001(1).xdbz",noAutoFit:false,defaultSheetId: '1'}], true);
            console.log( state.viewApp.loadModels,'加载模型');
                

          
           },
          
        // Show_File(type,filePath){
        //     let params = {
        //         type:type,
        //         filePath:filePath
        //     }
        //     Show_File(params).then(res=>{
        //         if (res.data.code == 1) {
        //             if (type == 2) {
        //                 res.data.data.forEach(element => {
        //                 state.imgList.push(state.baseUrl+element)
        //             });
        //             }else{
        //                 state.fileSrcUrl = state.baseUrl+res.data.data;
        //                 state.fileShow = 4;
        //             }
                    
        //         }
        //     })
        // },
        indexClick(){
                store.commit("SetnarbarMenuActive", 1);
                setStorage("narbarMenuActive", 1);
                router.push('/home/index');
            },
            courseClick(){
                router.push('/home/boutiqueCases');
            },
            detailsClick(){
                router.push('/home/casesDetail');
            },
            showChildren(){
                state.documentChildren = !state.documentChildren
            },
            // 切换
            pdfClick(row){
               
                 const index = row.fileData.fileName.lastIndexOf('.');
                 const format = '.'+row.fileData.fileName.substring(index+1,row.fileData.fileName.length);
            state.viewApp.unloadModel(state.pastId, 1) //卸载上一个模型

            // state.viewApp.stop(); //停止

            // state.viewApp = new pkpmView(state.viewOptions) // 创建实例

            // state.viewApp.start(); //启动
            state.viewApp.loadModels([{documentId: row.file, type: format, version: 1, noAutoFit:false,defaultSheetId: '1'}], true);
           


                // state.fileSrcUrl =state.baseUrl+'/'+item3.fileData.filePath;
                // console.log(state.fileSrcUrl);
                // const index = item3.fileData.fileName.lastIndexOf('.');
                // console.log(index);
                // const resvles = item3.fileData.fileName.substring(index+1,item3.fileData.fileName.length);
                // console.log(resvles);
                //     if (resvles == 'xlsx') {
                //         state.fileShow = 1;
                //     }else if(resvles == 'docx'){
                //         state.fileShow = 2;
                //     }else if(resvles == 'pdf'){
                //         state.fileShow = 4;
                       
                //     }else if(resvles == 'png' || resvles == 'jpg'){
                //         state.fileShow = 4;
                //     }else if(resvles == 'ppt' || resvles == 'pptx'){
                //         state.fileShow = 5;
                //         methods.Show_File(2,item3.fileData.filePath)
                //     }else if(resvles == 'doc'){
                //         methods.Show_File(1,item3.fileData.filePath)
                //     }
                //     else{
                //         ElMessage({
                //                 type: 'info',
                //                 message: '暂不支持查看此文件类型',
                //                 })
                //     }
                //     console.log(state.fileShow);
            },
    }
    onMounted(() => {
        if (location.href.indexOf("#reloaded") == -1) {
            location.href = location.href + "#reloaded";
            location.reload();
        }
        methods.f_get_csConstructionDrawings();
        //    let itemcasesDetail = getStorage('itemcasesDetail')
        //    state.TUzhiArr = itemcasesDetail.TUzhiArr
        //    state.Url = itemcasesDetail.TUzhiArr[0].fileSrc[0].url
        //    state.title = itemcasesDetail.title
        })
        return{
            ...toRefs(state),
            ...methods,
        }
    },
    components: { VueOfficeDocx,VueOfficeExcel,VueOfficePdf,bimModel},
}
</script>
<style  lang="scss" scoped>
@import '../../../../node_modules/pkpm.ViewEngine/lib/pkpm.ViewEngine.min.css';
    #renderContainer{
        width: 100%;
        height: 960px;
    }
#courseIntroductionDetails{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
    }
    .breadCrumbs{
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .rightBox{
        width:354px;
        height:auto;
        background: #fff;
        float: right;
    }
    
   :deep .el-collapse-item__header{
        background: #F7F8FA;
    }
    :deep .el-collapse-item__wrap{
        border: none;
    }
}
</style>